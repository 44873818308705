import { Form, Input, Select } from "antd";
import { email, max, min, required } from "../../helpers/FormRuleHelper";
import {
  getConstantValue,
  getFormattedDate,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import SearchSelect from "../common/SearchSelect";
import { UserCrudApi } from "../../client/EntityApi";
import InputPhone from "../common/InputPhone";
import { isRole } from "../../helpers/AuthHelper";
import TableSearchFilter from "../common/TableSearchFilter";
import AgentSearchSelect from "../common/AgentSearchSelect";
import StatusIndicator from "../common/StatusIndicator";

export const getLeadCPColumns = (user: any, constants?: any) => {
  let cols = [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Ad Soyad",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "E-Posta",
      dataIndex: "email",
      key: "email",
      render: (value: any) => value ?? "-",
    },
    { title: "Telefon", dataIndex: "phone", key: "phone" },
    {
      title: "Durum",
      dataIndex: "status",
      key: "status",
      filters: constants?.leadStatus,
      render: (value: any) => (
        <StatusIndicator
          status={getConstantValue(value, constants?.leadStatus)}
        />
      ),
    },
    {
      title: "Kayıt Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (value: any) => getFormattedDate(value),
    },
    {
      title: "Son Görüşme Tarihi",
      dataIndex: "lastNoteDate",
      key: "lastNoteDate",
      sorter: true,
      render: (value: any) => (value ? getFormattedDate(value) : "-"),
    },
    {
      title: "Aramaya Kalan Süre",
      dataIndex: "timeRemainingToCall",
      key: "timeRemainingToCall",
      sorter: true,
      render: (_: any, row: any) => row.timeRemainingToDay ?? "Aranmayacak",
    },
    {
      title: "Kaynak",
      dataIndex: "source",
      key: "source",
      filters: constants?.leadSources,
      render: (value: any) =>
        value ? getConstantValue(value, constants?.leadSources) : "-",
    },
    {
      title: "İlgi Alanları",
      dataIndex: "areaOfInterest",
      key: "areaOfInterest",
      filters: constants?.leadAois,
      render: (value: any) =>
        value ? getConstantValue(value, constants?.leadAois) : "-",
    },
  ] as any;

  if (isRole(user, "ROLE_MANAGER") || isRole(user, "ROLE_ADMIN")) {
    cols.splice(1, 0, {
      title: "Temsilci / Ekip",
      dataIndex: "agent",
      key: "agent.id",
      render: (value: any) =>
        (value?.fullName ?? "-") + " / " + (value?.teamName ?? "-"),
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <AgentSearchSelect
            style={{ width: 200 }}
            optionValueKey="id"
            allowClear={false}
            value={props.selectedKeys[0]}
            onSelect={(value: any) => props.setSelectedKeys([value])}
          />
        </TableSearchFilter>
      ),
    });
  }

  return cols;
};

export const getLeadCPFormItems = (
  user: any,
  constants?: any,
  isEdit = false
) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="@id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      {!isEdit && (
        <Form.Item
          label="Durum"
          name="status"
          rules={[required]}
          initialValue="newRegister"
        >
          <Select
            allowClear
            options={constants?.leadStatus}
            placeholder="Durum seçiniz"
          />
        </Form.Item>
      )}
      <Form.Item
        label="Ad Soyad"
        name="fullName"
        rules={[required, min(4), max(60)]}
      >
        <Input placeholder="Ad soyad giriniz" />
      </Form.Item>
      <Form.Item label="Telefon" name="phone" rules={[required]}>
        <InputPhone />
      </Form.Item>
      <Form.Item label="E-Posta" name="email" rules={[email]}>
        <Input placeholder="E-Posta giriniz" />
      </Form.Item>
      <Form.Item label="Kaynak" name="source">
        <Select
          allowClear
          options={constants?.leadSources}
          placeholder="Kaynak seçiniz"
        />
      </Form.Item>
      <Form.Item label="İlgi Alanı" name="areaOfInterest">
        <Select
          allowClear
          options={constants?.leadAois}
          placeholder="İlgi Alanı seçiniz"
        />
      </Form.Item>
      {(isRole(user, "ROLE_MANAGER") || isRole(user, "ROLE_ADMIN")) && (
        <Form.Item label="Temsilci (Aramak için yazın)" name="agent">
          <SearchSelect
            searchAction={(searchValue, setOptions, setLoading) =>
              UserCrudApi.getAll(1, 10, searchValue)
                .then((response) => {
                  setOptions(
                    getOptionsFromEntities(response["hydra:member"], "fullName")
                  );
                })
                .finally(() => setLoading(false))
            }
          />
        </Form.Item>
      )}
    </>
  );
};
