import "dayjs/locale/tr";
import locale from "antd/locale/tr_TR";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

const App = () => {
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          fontFamily: "'Poppins', sans-serif",
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default App;
