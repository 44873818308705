import { Form, Input } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import SearchSelect from "../common/SearchSelect";
import { UserCrudApi } from "../../client/EntityApi";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";
import { isRole } from "../../helpers/AuthHelper";

export const getTeamCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Ekip Adı", dataIndex: "name", key: "name" },
    { title: "Temsilci Sayısı", dataIndex: "teamSize", key: "teamSize" },
    {
      title: "Yönetici",
      dataIndex: "manager",
      key: "manager",
      render: (value: any) => value?.fullName ?? "-",
    },
  ];
};

export const getTeamCPFormItems = (user: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="@id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        label="Ekip Adı"
        name="name"
        rules={[required, min(2), max(50)]}
      >
        <Input />
      </Form.Item>
      {isRole(user, "ROLE_ADMIN") && (
        <Form.Item label="Yönetici" name="manager">
          <SearchSelect
            searchAction={(searchValue, setOptions, setLoading) =>
              UserCrudApi.getAll(1, 10, searchValue)
                .then((response) => {
                  setOptions(
                    getOptionsFromEntities(response["hydra:member"], "fullName")
                  );
                })
                .finally(() => setLoading(false))
            }
          />
        </Form.Item>
      )}
    </>
  );
};
