import {
  Typography,
  Image,
  Row,
  Col,
  Form,
  Input,
  Button,
  Divider,
  Grid,
  message,
} from "antd";
import { colors } from "../theme";
import { CheckCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import { email, len, max, min, required } from "../helpers/FormRuleHelper";
import { useRef, useState } from "react";
import InputPhone from "../components/common/InputPhone";
import GeneralApi from "../client/GeneralApi";
import { useForm } from "antd/lib/form/Form";
import ReCAPTCHA from "react-google-recaptcha";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const LeadLandingPage = () => {
  const screens = useBreakpoint();
  const recaptcha = useRef<any>(null);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div
        style={{
          position: "relative",
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: "url(assets/landing/landing-bg-2.png)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundAttachment: "fixed",
            WebkitBackgroundSize: "cover",
            backgroundSize: "cover",
            filter: "blur(6px)",
            zIndex: 2,
          }}
        />
        <Row
          style={{ padding: 34, zIndex: 3, maxWidth: "1300px" }}
          justify="center"
          gutter={[24, 24]}
        >
          <Col span={24}>
            <Row justify={"center"}>
              <Image
                src="assets/logos/marbas-big.png"
                preview={false}
                style={{ height: 90 }}
              />
            </Row>
          </Col>
          <Col span={24}>
            <Title style={{ textAlign: "center" }}>
              Size Ulaşmamız İçin Bilgilerinizi Bırakın
            </Title>
            <Title
              level={3}
              style={{
                textAlign: "center",

                fontWeight: "normal",
              }}
            >
              Marbaş Menkul Değerler ile yatırımlarınıza değer katın!
            </Title>
          </Col>
          <Col span={24}>
            <Row
              gutter={[24, 24]}
              style={{ padding: screens.lg ? "40px 0" : "" }}
              align="middle"
            >
              <Col
                lg={12}
                xs={24}
                style={{ paddingBottom: screens.lg ? "" : 40 }}
              >
                <Row gutter={[24, 40]}>
                  <Col span={24}>
                    <Row align="middle">
                      <Col xs={4} sm={3}>
                        <CheckCircleOutlined
                          style={{
                            fontSize: 40,
                            backgroundColor: colors.success,
                            borderRadius: "50%",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                        />
                      </Col>
                      <Col xs={20} sm={21}>
                        <Title
                          level={5}
                          style={{
                            marginBottom: 0,
                            marginTop: 10,
                          }}
                        >
                          Marbaş Menkul Değerler avantajlarından yararlanabilmek
                          için lütfen formu doğru ve eksiksiz bir şekilde
                          doldurunuz.
                        </Title>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row align="middle">
                      <Col xs={4} sm={3}>
                        <CheckCircleOutlined
                          style={{
                            fontSize: 40,
                            backgroundColor: colors.success,
                            borderRadius: "50%",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                        />
                      </Col>
                      <Col xs={20} sm={21}>
                        <Title
                          level={5}
                          style={{
                            marginBottom: 0,
                            marginTop: 10,
                          }}
                        >
                          Formu doldurduktan sonra çalışma süreleri içerisinde 1
                          saat içerisinde tarafınıza Marbaş Menkul Değerler
                          tarafından dönüş sağlanacaktır.
                        </Title>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row align="middle">
                      <Col xs={4} sm={3}>
                        <CheckCircleOutlined
                          style={{
                            fontSize: 40,
                            backgroundColor: colors.success,
                            borderRadius: "50%",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                        />
                      </Col>
                      <Col xs={20} sm={21}>
                        <Title
                          level={5}
                          style={{
                            marginBottom: 0,
                            marginTop: 10,
                          }}
                        >
                          Marbaş Menkul Değerler ailesine katılmak bu kadar
                          kolay.
                        </Title>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={12}
                xs={24}
                style={{ paddingBottom: screens.lg ? "" : 40 }}
              >
                <Row
                  style={{
                    border: "1px solid white",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    // backgroundColor: colors.white,
                    boxShadow: "0 0 25px rgba(0,0,0, 0.4)",
                    borderRadius: 8,
                    padding: screens.xs ? "10px 10px" : "10px 40px",
                    position: "relative",
                  }}
                  gutter={[12, 12]}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      filter: "blur(2px)",
                      zIndex: -1,
                      height: "100%",
                      width: "100%",
                    }}
                  ></div>
                  <img
                    src="assets/landing/gift-img.png"
                    alt="gift-image"
                    style={{
                      position: "absolute",
                      height: screens.xs ? 200 : 325,
                      width: screens.xs ? 275 : 400,
                      top: screens.xs ? -12 : -18,
                      right: screens.xs ? -56 : -84,
                    }}
                  />
                  <Col span={24}>
                    <Title
                      level={2}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      HIZLI BAŞVURU FORMU
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Form
                      form={form}
                      autoComplete="off"
                      layout="vertical"
                      onFinish={(values) => {
                        const captchaValue = recaptcha.current?.getValue();
                        if (!captchaValue) {
                          message.warning("Lütfen reCAPTCHA'yı doğrulayın!");
                          return;
                        }
                        setLoading(true);
                        GeneralApi.createLead({
                          ...values,
                          captcha: captchaValue,
                        })
                          .then((response) => {
                            message.success(response.message);
                            form.resetFields();
                            recaptcha.current?.reset();
                          })
                          .finally(() => setLoading(false));
                      }}
                    >
                      <Form.Item
                        label="İsim Soyisim"
                        name="fullname"
                        rules={[min(4), max(255), required]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Telefon"
                        name="phone"
                        rules={[required, len(10)]}
                      >
                        <InputPhone />
                      </Form.Item>
                      <Form.Item label="E-posta" name="email" rules={[email]}>
                        <Input />
                      </Form.Item>
                      <Form.Item label={null}>
                        <Row justify="center">
                          <ReCAPTCHA
                            ref={recaptcha}
                            sitekey={
                              process.env.REACT_APP_CAPTCHA_API_KEY ?? ""
                            }
                          />
                        </Row>
                      </Form.Item>
                      <Form.Item label={null}>
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                          block
                          style={{ backgroundColor: "#e60101" }}
                        >
                          HEMEN BAŞVUR
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Divider
        style={{
          margin: "-20px 0 0 0",
          padding: 0,
          position: "relative",
          maxWidth: "1300px",
        }}
      >
        <DownCircleOutlined
          style={{
            position: "absolute",
            zIndex: 4,
            fontSize: 50,
            backgroundColor: colors.success,
            borderRadius: "50%",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            animation: "moveUpDown 2s ease-in-out infinite",
          }}
          onClick={() => {
            const target = document.getElementById("second-area");
            if (target) {
              target.scrollIntoView({ behavior: "smooth" });
            }
          }}
        />
      </Divider>
      <div
        id="second-area"
        style={{
          display: "flex",
          justifyContent: "center",
          overflowX: "hidden",
        }}
      >
        <Row
          style={{
            padding: "80px 34px 34px 34px",
            maxWidth: "1300px",
          }}
          gutter={[24, 40]}
        >
          <Col span={24}>
            <Title style={{ textAlign: "center" }}>
              Marbaş farkı ile tanışın
            </Title>
          </Col>
          <Col span={24}>
            <Row align="middle">
              <Col sm={12} xs={24}>
                <Row justify={screens.xs ? "center" : "start"}>
                  <Image
                    preview={false}
                    src="assets/landing/landing-img-1.png"
                    alt="landing-image"
                    style={{
                      border: `3px solid ${colors.success}`,
                      padding: 20,
                      borderRadius: 16,
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px",
                    }}
                  />
                </Row>
              </Col>
              <Col sm={12} xs={24}>
                <Title
                  level={4}
                  style={{ textAlign: screens.xs ? "start" : "right" }}
                >
                  En uygun komisyon oranları ile alım-satım işlemlerinde
                  komisyonu düşünmeyin.
                </Title>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row align="middle">
              <Col sm={12} xs={24} style={{ order: screens.xs ? 2 : 1 }}>
                <Title level={4}>
                  Online para yatırma ile uzun prosedürlere takılmadan işlem
                  yapın.
                </Title>
              </Col>
              <Col sm={12} xs={24} style={{ order: screens.xs ? 1 : 2 }}>
                <Row justify={screens.xs ? "center" : "end"}>
                  <Image
                    preview={false}
                    src="assets/landing/landing-img-2.png"
                    style={{
                      border: `3px solid ${colors.success}`,
                      padding: 20,
                      borderRadius: 16,
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px",
                    }}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Divider style={{ marginTop: 0 }} />
              </Col>
              <Col span={24}>
                <Title level={5} style={{ fontWeight: "normal" }}>
                  MARBAŞ Menkul Değerler © 2024 | Tüm Hakları Saklıdır.
                </Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LeadLandingPage;
