interface IStatusIndicatorProps {
  status: any;
  style?: any;
}

const StatusIndicator = (props: IStatusIndicatorProps) => {
  let borderColor = "";
  let backgroundColor = "";

  switch (props.status) {
    case "Ulaşılamadı 1":
    case "Ulaşılamadı 2":
    case "Ulaşılamadı 3":
    case "Ulaşılamadı 4":
    case "İlgilenmiyor":
      borderColor = "orange";
      backgroundColor = "orange";
      break;
    case "Az İlgili":
    case "Çok İlgili":
    case "Evrak Gönderildi":
    case "Hesap Açıldı (Fonlu)":
    case "Hesap Açıldı (Fonsuz)":
    case "Yeni kayıt":
      borderColor = "green";
      backgroundColor = "green";
      break;
    case "Mevcut Müşteri":
    case "Mükerrer":
    case "Bir Daha Asla":
      borderColor = "black";
      backgroundColor = "black";
      break;
    default:
      borderColor = "transparent";
      backgroundColor = "transparent";
  }

  return (
    <div
      style={{
        border: `1px solid ${borderColor}`,
        borderRadius: 8,
        padding: "6px 14px",
        display: "flex",
        width: props.style ? "" : 130,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...props.style,
      }}
    >
      <div
        style={{
          padding: 5,
          borderRadius: 50,
          backgroundColor: backgroundColor,
          color: "transparent",
          marginRight: props.style ? 10 : 10,
          marginLeft: -10,
        }}
      />
      {props.status}
    </div>
  );
};

export default StatusIndicator;
