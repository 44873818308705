import { Col, Row, Tooltip, Typography } from "antd";
import { colors } from "../../theme";

const { Text } = Typography;

const DetailCard = (props: any) => {
  return (
    <div
      style={{
        backgroundColor: colors.white,
        padding: "10px 20px",
        borderRadius: 8,
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
        margin: "10px 10px",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <Row>
        <Col
          span={24}
          style={{
            display: props.title ? "block" : "none",
            borderBottom: "2px solid #9d9b9b",
            paddingBottom: 4,
            fontSize: "90%",
          }}
        >
          <Row justify="start">{props.title}</Row>
        </Col>

        <Col span={24} style={{ paddingTop: props.title ? 10 : "" }}>
          <Row justify="center" style={{ fontWeight: 500 }} align="middle">
            <Tooltip title={props.children}>
              <Text
                style={{
                  maxWidth: 300,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {props.children}
              </Text>
            </Tooltip>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DetailCard;
