import { Col, Row, Image } from "antd";
import { colors } from "../theme";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../context";
import { isRole } from "../helpers/AuthHelper";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const menus = () => {
    let menus = [
      {
        title: "Müşteri Adayları",
        to: "musteri-adaylari",
        src: "../assets/menu/musteri-adaylari.jpg",
      },
    ];

    if (isRole(user, "ROLE_MANAGER") || isRole(user, "ROLE_ADMIN")) {
      menus.push(
        {
          title: "Temsilciler",
          to: "temsilciler",
          src: "../assets/menu/temsilciler.jpg",
        },
        {
          title: "Ekipler",
          to: "ekipler",
          src: "../assets/menu/ekipler.jpg",
        }
      );
    }

    return menus;
  };

  return (
    <Row gutter={[16, 32]} justify="center">
      {menus().map((menu, index) => (
        <Col key={index}>
          <div className="menu-card" onClick={() => navigate(menu.to)}>
            <Row justify="center">
              <Image
                style={{ borderRadius: 8 }}
                src={menu.src}
                preview={false}
                width={200}
                height={200}
              />
            </Row>
            <Row
              justify="center"
              style={{ fontSize: 18, color: colors.white, marginTop: 8 }}
            >
              {menu.title}
            </Row>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default Dashboard;
