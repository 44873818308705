import { api } from "./Axios";
import { getCrudApi } from "./CrudApi";

export const UserCrudApi = getCrudApi("users");
export const TeamCrudApi = getCrudApi("teams");
export const LeadCrudApi = getCrudApi("leads");

export const LeadApi = {
  notesOfLead: (
    id: number,
    page?: number,
    pageSize?: number,
    search?: string,
    orders?: string[],
    filters?: string[]
  ) => {
    let queries: any[] = [];
    if (page) queries.push(`page=${page}`);
    if (pageSize) queries.push(`itemsPerPage=${pageSize}`);
    if (search) queries.push(`search=${search}`);
    if (orders) queries.push(...orders);
    if (filters) queries.push(...filters);

    return api
      .get("leads/" + id + "/notes?" + queries.join("&"))
      .then((response: any) => response.data);
  },
  changeAgent: (leads: string[], agent: string) => {
    return api
      .post("change-agent", { leads: leads, agent: agent })
      .then((response: any) => response.data);
  },
};
