import { api } from "./Axios";

const GeneralApi = {
  getConstants: () => {
    return api.get("constants").then((response: any) => response.data);
  },
  createLead: (values: any) => {
    return api.post("v1/lead", values).then((response: any) => response.data);
  },
};

export default GeneralApi;
