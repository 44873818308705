import { useParams } from "react-router-dom";
import { LeadCrudApi } from "../client/EntityApi";
import CrudTable, {
  ICrudTableRefMethods,
} from "../components/common/crud/CrudTable";
import { useContext, useEffect, useRef, useState } from "react";
import { ConstantContext } from "../context";
import {
  getNoteCPColumns,
  getNoteCPFormItems,
} from "../components/crud/NoteCPComponent";
import { Col, Row, Spin, Typography } from "antd";
import { getCrudApi } from "../client/CrudApi";
import { colors } from "../theme";
import StatusChangeModal, {
  IStatusChangeModalRefMethods,
} from "../components/modals/StatusChangeModal";
import { getConstantValue, getFormattedDate } from "../helpers/UtilHelper";
import StatusIndicator from "../components/common/StatusIndicator";
import DetailCard from "../components/common/DetailCard";
import { SwapOutlined } from "@ant-design/icons";

const { Text } = Typography;

const LeadDetailPage = () => {
  const crudTableRef = useRef<ICrudTableRefMethods>(null);
  const statusChangeModalRef = useRef<IStatusChangeModalRefMethods>(null);
  const { id } = useParams();
  const constants = useContext(ConstantContext);
  const [leadLoading, setLeadLoading] = useState(false);
  const [lead, setLead] = useState<any>();

  useEffect(() => {
    if (!id) return;
    refreshLead(id);
  }, [id]);

  const refreshLead = (id: string) => {
    setLeadLoading(true);
    LeadCrudApi.get(parseInt(id))
      .then((response) => setLead(response))
      .finally(() => setLeadLoading(false));
  };

  return (
    <>
      <Row
        style={{
          backgroundColor: colors.white,
          borderRadius: 4,
          marginBottom: 8,
          padding: 16,
        }}
      >
        {leadLoading ? (
          <Spin size="small" />
        ) : (
          lead && (
            <Col
              span={24}
              style={{
                borderRadius: 8,
              }}
            >
              <Row>
                <Col xxl={6} md={8} sm={12} xs={24}>
                  <DetailCard>{lead.fullName}</DetailCard>
                </Col>
                <Col xxl={5} md={8} sm={12} xs={24}>
                  <DetailCard>{lead.email}</DetailCard>
                </Col>
                <Col xxl={5} md={8} sm={12} xs={24}>
                  <DetailCard>{lead.phone}</DetailCard>
                </Col>
                <Col xxl={4} sm={12} xs={24}>
                  <StatusIndicator
                    status={getConstantValue(
                      lead.status,
                      constants?.leadStatus
                    )}
                    style={{
                      backgroundColor: colors.white,
                      padding: "10px 20px",
                      borderRadius: 8,
                      boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 15px",
                      margin: "10px 10px",
                    }}
                  />
                </Col>
                <Col xxl={4} sm={12} xs={24}>
                  <DetailCard
                    onClick={() => {
                      statusChangeModalRef.current?.form.setFieldsValue({
                        id: id,
                        "@id": "/api/leads/" + id,
                      });
                      statusChangeModalRef.current?.setOpen(true);
                    }}
                    style={{ cursor: "pointer", backgroundColor: "gold" }}
                  >
                    <SwapOutlined /> Durum Değiştir
                  </DetailCard>
                </Col>

                <Col xl={5} sm={12} xs={24}>
                  <DetailCard title="Kayıt Tarihi">
                    {getFormattedDate(lead.createdAt)}
                  </DetailCard>
                </Col>
                <Col xl={4} lg={12} sm={12} xs={24}>
                  <DetailCard title="Kalan Süre">
                    {lead.timeRemainingToDay} Gün
                  </DetailCard>
                </Col>
                <Col xl={5} md={8} sm={12} xs={24}>
                  <DetailCard title="Son Not Tarihi">
                    {lead.lastNoteDate
                      ? getFormattedDate(lead.lastNoteDate)
                      : "-"}
                  </DetailCard>
                </Col>
                <Col xl={5} md={8} sm={12} xs={24}>
                  <DetailCard title="Kaynak">
                    {lead.source
                      ? getConstantValue(lead.source, constants?.leadSources)
                      : "-"}
                  </DetailCard>
                </Col>
                <Col xl={5} md={8} sm={12} xs={24}>
                  <DetailCard title="İlgi Alanı">
                    {lead.areaOfInterest
                      ? getConstantValue(
                          lead.areaOfInterest,
                          constants?.leadAois
                        )
                      : "-"}
                  </DetailCard>
                </Col>
              </Row>
            </Col>
          )
        )}
      </Row>
      <CrudTable
        ref={crudTableRef}
        hideSearchBar
        entityLabel="Not"
        api={getCrudApi("leads/" + id + "/notes")}
        columns={getNoteCPColumns(constants)}
        addFormItems={getNoteCPFormItems(constants)}
        editFormItems={getNoteCPFormItems(constants, true)}
        expandable={{
          expandedRowRender: (row) => (
            <div style={{ maxWidth: "80vw" }}>
              <Text strong>İçerik: </Text> <Text>{row.content}</Text>
            </div>
          ),
        }}
      />
      <StatusChangeModal
        ref={statusChangeModalRef}
        afterAction={() => {
          if (id) refreshLead(id);
          crudTableRef.current?.refreshData();
        }}
      />
    </>
  );
};

export default LeadDetailPage;
