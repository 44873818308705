import { Dropdown, MenuProps, Row, Avatar, Divider } from "antd";
import {
  SettingOutlined,
  LockOutlined,
  LogoutOutlined,
  LoadingOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useContext, useRef, useState } from "react";
import { UserContext } from "../../context";
import ProfileUpdateModal, {
  IProfileUpdateModalRefMethods,
} from "../modals/ProfileUpdateModal";
import PasswordUpdateModal, {
  IPasswordUpdateModalRefMethods,
} from "../modals/PasswordUpdateModal";
import { colors } from "../../theme";
import { removeCredentials } from "../../helpers/AuthHelper";

const ProfileMenu = () => {
  const { user } = useContext(UserContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const profileUpdateModalRef = useRef<IProfileUpdateModalRefMethods>(null);
  const passwordUpdateModalRef = useRef<IPasswordUpdateModalRefMethods>(null);

  const items: MenuProps["items"] = [
    {
      key: "0",
      onClick: () => profileUpdateModalRef.current?.setOpen(true),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>{user ? user?.fullName : <LoadingOutlined />}</span>
          <SettingOutlined spin />
        </Row>
      ),
    },
    {
      key: "1",
      onClick: () => passwordUpdateModalRef.current?.setOpen(true),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>Şifremi Güncelle</span>
          <LockOutlined />
        </Row>
      ),
    },
    {
      key: "2",
      label: <Divider style={{ margin: 0 }} />,
    },
    {
      key: "3",
      danger: true,
      onClick: () => removeCredentials(),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>Çıkış Yap</span>
          <LogoutOutlined />
        </Row>
      ),
    },
  ];

  return (
    <>
      <Dropdown
        menu={{ items }}
        placement="bottomRight"
        trigger={["click"]}
        className={
          isDropdownOpen ? "profileMenu-card-open" : "profileMenu-card-close"
        }
        onOpenChange={(open) => setIsDropdownOpen(open)}
      >
        <span
          style={{
            color: colors.white,
            cursor: "pointer",
            alignContent: "center",
          }}
        >
          <Avatar style={{ marginRight: 8 }}>
            {user ? user.fullName.charAt(0).toUpperCase() : <LoadingOutlined />}
          </Avatar>
          {user ? user?.fullName : <LoadingOutlined />}
          <DownOutlined
            style={{
              marginLeft: 8,
              transition: "transform 0.3s ease",
              transform: isDropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </span>
      </Dropdown>
      <ProfileUpdateModal ref={profileUpdateModalRef} />
      <PasswordUpdateModal ref={passwordUpdateModalRef} />
    </>
  );
};

export default ProfileMenu;
