import { useContext } from "react";
import { TeamCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getTeamCPColumns,
  getTeamCPFormItems,
} from "../../components/crud/TeamCPComponent";
import { UserContext } from "../../context";

const TeamCrudPage = () => {
  const { user } = useContext(UserContext);

  return (
    <CrudTable
      entityLabel="Ekip"
      api={TeamCrudApi}
      columns={getTeamCPColumns()}
      addFormItems={getTeamCPFormItems(user)}
      editFormItems={getTeamCPFormItems(user, true)}
      setEditFields={(row) => {
        if (!row.manager) return row;
        return {
          ...row,
          manager: { label: row.manager.fullName, value: row.manager["@id"] },
        };
      }}
      beforeEditOperation={(values: any) => {
        if (!("manager" in values)) return values;

        let manager = null;
        if (values.manager) {
          manager =
            typeof values.manager === "object"
              ? values.manager.value
              : values.manager;
        }

        return { ...values, manager: manager };
      }}
    />
  );
};

export default TeamCrudPage;
