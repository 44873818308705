import { UserCrudApi } from "../../client/EntityApi";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";
import SearchSelect from "./SearchSelect";

const AgentSearchSelect = (props: any) => {
  const { optionValueKey, ...rest } = props;

  return (
    <SearchSelect
      {...rest}
      placeholder="Temsilci aramak için yazın.."
      searchAction={(searchValue, setOptions, setLoading) => {
        return UserCrudApi.getAll(1, 10, searchValue)
          .then((response) => {
            setOptions(
              getOptionsFromEntities(
                response["hydra:member"],
                "fullName",
                optionValueKey ?? "@id"
              )
            );
          })
          .finally(() => setLoading(false));
      }}
    />
  );
};

export default AgentSearchSelect;
