import { Form, Input, Select } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import { useContext } from "react";
import { ConstantContext } from "../../context";
import { getNoteCPFormItems } from "../crud/NoteCPComponent";

const StatusChangeForm = () => {
  const constants = useContext(ConstantContext);

  return (
    <>
      <Form.Item name="id" rules={[required]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name="@id" rules={[required]} hidden>
        <Input />
      </Form.Item>
      <Form.Item label="Durum" name="status" rules={[required]}>
        <Select
          allowClear
          placeholder="Durum seçiniz"
          options={constants?.leadStatusTime}
        />
      </Form.Item>
      {getNoteCPFormItems(constants)}
    </>
  );
};

export default StatusChangeForm;
