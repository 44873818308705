import { useContext, useRef, useState } from "react";
import { LeadApi, LeadCrudApi } from "../../client/EntityApi";
import CrudTable, {
  ICrudTableRefMethods,
} from "../../components/common/crud/CrudTable";
import {
  getLeadCPColumns,
  getLeadCPFormItems,
} from "../../components/crud/LeadCPComponent";
import { ConstantContext, UserContext } from "../../context";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { ExpandOutlined, SwapOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import StatusChangeModal, {
  IStatusChangeModalRefMethods,
} from "../../components/modals/StatusChangeModal";
import { isRole } from "../../helpers/AuthHelper";
import { Button, Col, message, Popconfirm, Row, Space, Typography } from "antd";
import AgentSearchSelect from "../../components/common/AgentSearchSelect";
import { colors } from "../../theme";

const { Text } = Typography;

const LeadCrudPage = () => {
  const crudTableRef = useRef<ICrudTableRefMethods>(null);
  const statusChangeModalRef = useRef<IStatusChangeModalRefMethods>(null);
  const contants = useContext(ConstantContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedAgent, setselectedAgent] = useState(undefined);
  const [assignLoading, setAssignLoading] = useState(false);
  const isNotUser = isRole(user, "ROLE_MANAGER") || isRole(user, "ROLE_ADMIN");

  return (
    <>
      {isNotUser && selectedRowKeys.length > 0 && (
        <Row
          justify="space-between"
          align="middle"
          style={{
            backgroundColor: colors.white,
            marginBottom: 8,
            padding: 16,
          }}
          className="row-slide-in"
        >
          <Col>
            <Text style={{ fontWeight: 600 }}>
              Müşteri adaylarının atanacağı temsilciyi seçiniz
            </Text>
          </Col>
          <Col>
            <Space>
              <AgentSearchSelect
                disabled={assignLoading}
                style={{ minWidth: 200 }}
                size="large"
                value={selectedAgent}
                onSelect={(value: any) => setselectedAgent(value)}
              />
              <Popconfirm
                title="Müşteri Adaylarını Taşı"
                description="Emin misiniz?"
                onConfirm={() => {
                  if (selectedRowKeys.length === 0) {
                    message.warning("En az 1 müşteri adayı seçmelisiniz.");
                    return;
                  }
                  if (!selectedAgent) {
                    message.warning("Temsilci boş bırakılamaz.");
                    return;
                  }

                  setAssignLoading(true);
                  LeadApi.changeAgent(
                    selectedRowKeys.map(
                      (selectedRowKey) => "/api/leads/" + selectedRowKey
                    ),
                    selectedAgent
                  )
                    .then((response) => {
                      message.success(response.message);
                      setSelectedRowKeys([]);
                      setselectedAgent(undefined);
                      crudTableRef.current?.refreshData();
                    })
                    .finally(() => setAssignLoading(false));
                }}
                onCancel={() => {}}
                okText="Evet"
                cancelText="Vazgeç"
              >
                <Button loading={assignLoading} size="large" type="primary">
                  Temsilciye Ata
                </Button>
              </Popconfirm>
            </Space>
          </Col>
        </Row>
      )}
      <CrudTable
        ref={crudTableRef}
        fixed="right"
        entityLabel="Müşteri Adayı"
        api={LeadCrudApi}
        columns={getLeadCPColumns(user, contants)}
        addFormItems={getLeadCPFormItems(user, contants)}
        editFormItems={getLeadCPFormItems(user, contants, true)}
        hideDeleteProcess={isRole(user, "ROLE_USER")}
        rowSelection={
          isNotUser && {
            selectedRowKeys: selectedRowKeys,
            onChange: setSelectedRowKeys,
          }
        }
        extraRowProcess={(row: any) => (
          <>
            <CrudTableProcessButton
              tooltipText="Müşteri Adayı Detay"
              icon={<ExpandOutlined />}
              onClick={() => navigate("/musteri-aday-detay/" + row.id)}
            />
            <CrudTableProcessButton
              tooltipText="Durum Değiştir"
              icon={<SwapOutlined />}
              onClick={() => {
                statusChangeModalRef.current?.form.setFieldsValue({
                  id: row.id,
                  "@id": row["@id"],
                });
                statusChangeModalRef.current?.setOpen(true);
              }}
            />
          </>
        )}
        setEditFields={(row) => {
          if (!row.agent) return row;
          return {
            ...row,
            agent: { label: row.agent.fullName, value: row.agent["@id"] },
          };
        }}
        beforeEditOperation={(values: any) => {
          if (!("agent" in values)) return values;

          let agent = null;
          if (values.agent) {
            agent =
              typeof values.agent === "object"
                ? values.agent.value
                : values.agent;
          }

          return { ...values, agent: agent };
        }}
      />
      <StatusChangeModal
        ref={statusChangeModalRef}
        afterAction={() => crudTableRef.current?.refreshData()}
      />
    </>
  );
};

export default LeadCrudPage;
